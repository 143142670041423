import React from "react";
import SEO from "../components/seo";
import Layout from "../components/layout";
import Container from "../components/container";

const KontaktDziekujemy = ({ data }) => {
  return (
    <Layout
      title="Dziękujemy"
      headline="za zainteresowanie naszymi rozwiązaniami"
    >
      <SEO
        title="Dziękujemy za zainteresowanie naszymi Programem Partnerskim"
        description="Kontakt ✅ Skontaktujemy się z Tobą, tak szybko, jak to możliwe • Questy - Twój biznes wygrywa"
        keywords={["kontakt"]}
      />
      <Container>
        <div
          style={{
            marginTop: 100,
          }}
        >
          <h1
            style={{
              fontSize: 24,
              marginBottom: 20,
              marginTop: 100,
            }}
          >
            Dziękujemy
            <br />
            za zainteresowanie Programem Partnerskim
          </h1>
        </div>
        <div>
          <center>
            <p>Skontaktujemy się z Tobą tak szybko, jak to tylko możliwe.</p>
          </center>
        </div>
      </Container>
    </Layout>
  );
};

export default KontaktDziekujemy;
